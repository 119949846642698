import React from 'react';
import { Link } from 'gatsby';

export default function NotFound() {
  return (
    <nav>
      <h2>Sorry, couldn&apos;t find that page.</h2>
      <Link to="/">go back</Link>
    </nav>
  );
}
